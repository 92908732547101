import globalStyles from '@global/global.css';
import { Quicksand } from '@next/font/google';
import * as Fathom from 'fathom-client';
import NextAdapterPages from 'next-query-params/pages';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'normalize.css';
import { useEffect } from 'react';
import { QueryParamProvider } from 'use-query-params';

// If loading a variable font, you don't need to specify the font weight
const quickSand = Quicksand({
  subsets: ['latin']
});
const Layout = dynamic(() => import('@components/layout/Layout'), {
  ssr: false
});
const App = ({
  Component,
  pageProps
}: AppProps) => {
  const router = useRouter();
  useEffect(() => {
    Fathom.load(process.env.FATHOM_SITE_ID || '', {
      includedDomains: ['staging.statemints.com', 'statemints.com']
    });
    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);
  globalStyles();
  return <>
            <style jsx global>{`
                html {
                    font-family: ${quickSand.style.fontFamily};
                }
            `}</style>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            <QueryParamProvider adapter={NextAdapterPages} data-sentry-element="QueryParamProvider" data-sentry-source-file="_app.tsx">
                <Layout data-sentry-element="Layout" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </Layout>
            </QueryParamProvider>
        </>;
};
export default App;