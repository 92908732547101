import type * as Stitches from '@stitches/react'
import { createStitches } from '@stitches/react'
export type { VariantProps } from '@stitches/react'

export const {
    config,
    createTheme,
    css,
    getCssText,
    globalCss,
    styled,
    theme
} = createStitches({
    theme: {
        colors: {
            boston: '#3F94B1',
            white: '#ffffff',
            white_smoke: '#F5F5F5',
            black: '#000000',
            cloudy_night: '#1D2530',
            midnight: '#262E3A',
            black_pearl: '#121820',
            dark: '#19202A',
            dark_lighter: '#2A3647',
            sky: '#63B9D7',
            navy: '#5878A5',
            cove: '#A7B7D1',
            gray: '#999999',
            light_gray: '#D9D9D9',
            dark_gray: '#333232',
            space_gray: '#E8EDF4',
            bluish_grey: '#7E8CA4',
            dark_grey: '#2D2D2D',
            orange: '#EF5A38',
            burnt: '#9C2C12',
            gold: '#F79014',
            yellow: '#FFC700',
            polo: '#83A6D4',
            tuna: '#4D5157',
            smoky: '#59595B',
            dim: '#6C6C6C',
            nobel: '#A0A0A0',
            cyan: '#a7b7d1',
            kashmir: '#566785',
            blue: '#367599',
            blue_vortex: '#3D4657',
            dark_pastel_blue: '#86A1C6',
            coarse: '#141921',
            gainsboro: '#DADADA',
            squid: '#303D54',
            ebony: '#2C313D',
            primary: '$boston',
            secondary: '$white'
        },
        space: {
            1: '5rem',
            2: '10rem',
            3: '15rem',
            4: '20rem',
            5: '25rem',
            6: '30rem',
            7: '35rem',
            8: '40rem',
            9: '45rem',
            10: '50rem'
        },
        letterSpacings: {
            1: '1rem'
        },
        radii: {
            1: '6rem',
            2: '10rem',
            3: '15rem',
            round: '50%',
            pill: '9999px'
        },
        fontSizes: {
            1: '10rem',
            2: '12rem',
            3: '14rem',
            4: '18rem',
            5: '24rem'
        },
        fontWeights: {
            extraLight: '100',
            light: '200',
            medium: '400',
            normal: '500',
            lightBold: '700',
            bold: '800'
        },
        sizes: {
            sm: '430rem',
            md: '715rem',
            lg: '1145rem',
            xl: '1500rem',
            hq: '1920rem'
        }
    },
    utils: {
        p: (value: Stitches.PropertyValue<'padding'>) => ({
            padding: value
        }),
        pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
            paddingTop: value
        }),
        pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
            paddingRight: value
        }),
        pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
            paddingBottom: value
        }),
        pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value
        }),
        px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
            paddingRight: value
        }),
        py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
            paddingBottom: value
        }),
        m: (value: Stitches.PropertyValue<'margin'>) => ({
            margin: value
        }),
        mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
            marginTop: value
        }),
        mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
            marginRight: value
        }),
        mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
            marginBottom: value
        }),
        ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
            marginLeft: value
        }),
        mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
            marginRight: value
        }),
        my: (value: Stitches.PropertyValue<'marginTop'>) => ({
            marginTop: value,
            marginBottom: value
        })
    },
    media: {
        sm: '(min-width: 320px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 1024px)',
        hq: '(min-width: 1450px)'
    }
})

export type CSS = Stitches.CSS<typeof config>
